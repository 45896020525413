import React from "react"
import AdSense from "react-adsense"

export const Ads: React.FC<{ type?: "base" }> = ({ type }) => {
  return (
    <div className="max-w-screen-md mt-4 mx-auto">
      <AdSense.Google
        client="ca-pub-1533832539203264"
        slot="4946174881"
        style={{ display: "block" }}
        layout="in-article"
        format="fluid"
      />
    </div>
  )
}
