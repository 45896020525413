import React, { ReactElement, useEffect, useState } from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import { Seo } from "../components/Seo"
import LayoutMdx from "../components/LayoutMdx"
import { Footer } from "../components/Footer"
import { Link, PageProps } from "gatsby"
import { Article } from "../entities/Article"
import { Articles } from "../components/Articles"
import { TinyNavbar } from "../components/TinyNavbar"
import { Container } from "../components/Container"
import ShareIcon from "@heroicons/react/solid/ShareIcon"

const Author: React.FC<{
  date: string
  image: string
  name: string
}> = props => {
  return (
    <div className={`flex items-center`}>
      <div className="flex-shrink-0">
        <span className="sr-only">{props.name}</span>
        <img
          className="w-10 h-10 rounded-full"
          src={props.image}
          alt={`image de profil de ${props.name}`}
        />
      </div>

      <div className="ml-3">
        <p className="font-medium text-gray-900">{props.name}</p>

        <p className="flex space-x-1 text-sm text-gray-400">
          <time dateTime={props.date}>Publié le {props.date}</time>
        </p>
      </div>
    </div>
  )
}

const Icon: React.FC<{
  to: string
  name: string
  icon: ReactElement
}> = props => (
  <a
    href={props.to}
    target="_blank"
    rel="noopener noreferrer"
    className="inline-block p-2 text-gray-300 hover:text-gray-400"
  >
    <span className="sr-only">{props.name}</span>
    <svg
      className="w-6 h-6"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      {props.icon}
    </svg>
  </a>
)

const Share: React.FC<{ title: string; description: string }> = props => {
  const [url, setUrl] = useState("")
  const [shareable, setShareable] = useState(false)

  useEffect(() => {
    setUrl(window.location.href)
    setShareable(!!navigator.share)
  }, [])

  return (
    <div className="flex items-center justify-end">
      <Icon
        name="Facebook"
        to={`https://www.facebook.com/sharer.php?u=${url}`}
        icon={
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        }
      />
      <Icon
        name="Twitter"
        to={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(
          props.title
        )}`}
        icon={
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        }
      />

      {shareable && (
        <div
          onClick={() =>
            navigator.share({
              url,
              title: props.title,
              text: props.description,
            })
          }
          className="p-2 text-gray-300 cursor-pointer hover:text-gray-400"
        >
          <ShareIcon className="w-6 h-6 " />
        </div>
      )}
    </div>
  )
}

const ArticlePage: React.FC<
  PageProps<any, Article & { categories: any[]; more: Article[] }>
> = props => {
  const article = props.pageContext
  const category = props.pageContext.category

  const breadcrumbs = [
    { label: category.title, url: category.url },
    { label: article.title, url: article.url },
  ]

  const illustration = getImage(article.illustration)

  return (
    <>
      <LayoutMdx>
        <Layout>
          <Seo
            title={article.seo__title}
            description={article.seo__description}
            image={article.image}
            breadcrumbs={breadcrumbs}
            url={props.location.href}
            article={article}
          />

          <TinyNavbar categories={article.categories} obfuscation />

          <article>
            <section className="container px-6 mx-auto mt-8 md:mt-16">
              <div className="max-w-3xl mx-auto">
                <Link
                  to={category.url}
                  className="block text-sm font-medium text-black uppercase"
                >
                  {category.title}
                </Link>

                <h1 className="block mt-2 text-xl font-semibold text-black lg:text-5xl">
                  {article.title}
                </h1>
              </div>
            </section>

            <section className="relative mt-8 overflow-hidden">
              <div className="relative px-4 sm:px-6 lg:px-8">
                <section className="max-w-3xl mx-auto">
                  <GatsbyImage
                    className="w-full rounded"
                    image={illustration}
                    alt=""
                  />
                </section>

                <section className="container flex items-center justify-between max-w-3xl mx-auto mt-4 lg:mt-8">
                  <Author
                    image={article.author.image}
                    name={article.author.name}
                    date={article.created_at}
                  />

                  <Share
                    title={article.title}
                    description={article.description}
                  />
                </section>

                <section className="mx-auto mt-6 antialiased">
                  <MDXRenderer>{article.html}</MDXRenderer>
                </section>
              </div>
            </section>
          </article>

          <Container className="mt-20">
            <Articles articles={article.more} />
          </Container>

          <Footer categories={article.categories} obfuscation />
        </Layout>
      </LayoutMdx>
    </>
  )
}

export default ArticlePage
