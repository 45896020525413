import React from "react"

export const H1: React.FC<{ id?: string }> = ({ children, id }) => (
  <h1 id={id} className="max-w-screen-md mx-auto text-4xl font-bold mt-14">
    {children}
  </h1>
)

export const H2: React.FC<{ id?: string }> = props => (
  <h2 id={props.id} className="max-w-screen-md mx-auto text-3xl font-bold mt-14">
    {props.children}
  </h2>
)

export const H3: React.FC<{ id?: string }> = ({ children, id }) => (
  <h3 id={id} className="max-w-screen-md mx-auto mt-10 text-2xl font-bold">
    {children}
  </h3>
)

export const H4: React.FC<{ id?: string }> = ({ children, id }) => (
  <h4 id={id} className="max-w-screen-md mx-auto mt-8 text-3xl text-gray-600">
    {children}
  </h4>
)

export const H5: React.FC<{ id?: string }> = ({ children, id }) => (
  <h5 id={id} className="max-w-screen-md mx-auto mt-8 text-2xl font-bold">
    {children}
  </h5>
)

export const H6: React.FC<{ id?: string }> = ({ children, id }) => (
  <h6 id={id} className="max-w-screen-md mx-auto mt-4 text-2xl">
    {children}
  </h6>
)
