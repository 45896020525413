import React from "react"
import { Link as GatsbyLink } from "gatsby"

export const Link: React.FC<{ href: string }> = ({ children, href }) => {
  if (href.includes("amazon"))
    return (
      <span
        className="underline cursor-pointer"
        onClick={() => (window.location.href = href)}
      >
        {children}
      </span>
    )

  if (href.charAt(0) !== "/" && !href.includes("sudoku"))
    return (
      <a href={href} rel="nofollow external">
        {children}
      </a>
    )

  return <GatsbyLink to={href}>{children}</GatsbyLink>
}
